import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-white justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["square-container", { 'bg-blue-500': _ctx.lps, 'white-border': !_ctx.lps }])
  }, [
    (_ctx.lps)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.index + 1), 1))
      : _createCommentVNode("", true)
  ], 2))
}