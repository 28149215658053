import { fetchPrograms, getSpecs } from "./apiRequest";
import { createStore } from "vuex";
import "./typeHolder";
import { CourseObject, Program, Spec } from "./typeHolder";

import State from "./state";

const state = new State();

const store = createStore<State>({
  state: state,
  mutations: {
    setPrograms(state, programs: Program[]) {
      state.courseState.programs = programs;
    },
    createSpecialization(state, program: Program) {
      state.createSpecialization(program);
    },

    switchProgram(state, program: Program){
      if (state.currentSpecialization){
        state.currentSpecialization.switchProgram(program);
      }
      
    },

    setSearchProgram(state, program: Program) {
      state.courseState.setSearchProgram(program);
    },

    setSpecs(state) {
      state.setSpecs();
    },

    setSelectedSpec(state, spec: Spec) {
      state.setSelectedSpec(spec);
    },

    setSpecializationSortedCourses(state) {
      if (state.currentSpecialization) {
        state.currentSpecialization.unsortedCourses = state.courseState.courses;
      }
    },

    setAddedCourse(
      state,
      payload: { course: CourseObject; year: string; operation: string }
    ) {
      const { course, year } = payload; // Destructuring course and year from the payload
      if (!state.currentSpecialization) {
        console.warn("Specialization is not initialized.");
        return;
      }
      state.currentSpecialization.addCourse(course, year);
      state.courseState.uppdateSameCourseCode(course);
    },

    setRemovedCourse(
      state,
      payload: { course: CourseObject; operation: string }
    ) {
      const course = payload.course;
      if (!state.currentSpecialization) {
        console.warn("Specialization instance is not initialized.");
        return;
      }
      state.currentSpecialization.removeCourse(course);
      state.courseState.uppdateSameCourseCode(course);
    },

    resetState(state) {
      state.resetState();
    },

    swapSpecialization(state, spec) {
      state.setCurrentSpec(spec);
    },
  },

  actions: {
    async fetchAllPrograms({ commit }) {
      try {
        const programs = await fetchPrograms();
        commit("setPrograms", programs);
      } catch (error) {
        console.error("Error fetching programs:", error);
      }
    },

    async createSpecialization({ dispatch, commit }, selectedProgram) {
      commit("createSpecialization", selectedProgram); // Committing selected program
      // Dispatch to setSearchProgram with a flag to commit 'setSpecs'
      await dispatch("setSearchProgram", {
        program: selectedProgram,
        commitSpecs: true,
      });
      commit("setSpecializationSortedCourses");
    },

    async switchProgram({ dispatch, commit }, selectedProgram) {
      commit("switchProgram", selectedProgram); // Committing selected program
      // Dispatch to setSearchProgram with a flag to commit 'setSpecs'
      await dispatch("setSearchProgram", {
        program: selectedProgram,
        commitSpecs: true,
      });
      commit("setSpecializationSortedCourses");
    },

    async setSearchProgram(
      { commit, state },
      { program, commitSpecs = false }
    ) {
      commit("setSearchProgram", program);

      await state.courseState.fetchStateCourses(); // Wait for courses to be fetched
      if (commitSpecs) {
        commit("setSpecs"); // Commit specs only if commitSpecs is true
      }
    },

    setSelectedSpec({ commit }, value) {
      commit("setSelectedSpec", value);
    },

    addCourse({ commit }, { course, year }) {
      commit("setAddedCourse", { course, year });
    },

    removeCourse({ commit }, { course }) {
      commit("setRemovedCourse", { course });
    },

    resetState({ commit }) {
      commit("resetState");
    },
  },

  getters: {
    programs: (state) => state.courseState.programs,
    specs: (state) => state.specs,
    selectedProgram: (state) => state.getSelectedProgram(),
    selectedSpec: (state) => state.getSpecializationSpec(),
    currentSpecialization: (state) => state.currentSpecialization,
    myList: (state) => state.specializations,
    progress: (state) => state.getProgress(),
    selectedCourses: (state) => state.getSelectedCourses(),
    schedule: (state) => state.getSchedule(),
    courseState: (state) => state.courseState,
  },
});

export default store;
