<template>
  <nav class="bg-gray-600">
    <div
      class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2"
    >
      <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
        <img
          src="/spec_logo.png"
          class="h-24"
        />
        <span class="self-center text-2xl font-semibold whitespace-nowrap"
          >Spec</span
        >
      </a>

      <div class="relative" ref="dropdown">
        <button
          type="button"
          @click="toggleDropdown"
          class="font-semibold text-lg p-1 rounded-lg pr-10 hover:bg-gray-500 focus:bg-gray-500"
          :class="{ 'text-gray-300': showDropdown }"
        >
          Mina Specialiseringar
        </button>

        <svg
          class="absolute right-2 top-1/2 transform -translate-y-1/2"
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
        <div
          v-if="showDropdown"
          class="absolute w-full mt-1 bg-white border border-gray-400 shadow-lg z-10"
          ref="dropdownMenu"
        >
          <div
            v-for="specialization in dropDownData"
            :key="specialization.program.programmeCode"
            class="px-4 py-2 flex items-center dropdown-item"
            @click="selectItem(specialization)"
          >
            {{
              specialization.program.programmeCode +
              " - " +
              specialization.spec?.specialisation_sv
            }}
          </div>
        </div>
      </div>
      <button
        type="button"
        class="text-white bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
          />
        </svg>
      </button>
    </div>
  </nav>
</template>

<script lang="ts">
import Specializationlization from "@/services/specialization";
import { defineComponent, PropType } from "vue";
import { mapMutations } from "vuex";

export default defineComponent({
  name: "TopNavbar",
  props: {
    dropDownData: {
      type: Array as PropType<Specializationlization[]>,
      required: true,
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    ...mapMutations(["swapSpecialization"]),
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectItem(currentSpecialization: Specializationlization) {
      this.swapSpecialization(currentSpecialization);
    },
    handleClickOutside(event: MouseEvent) {
      const target = event.target as Node;
      const dropdown = this.$refs.dropdown as HTMLElement;
      if (!dropdown.contains(target)) {
        this.showDropdown = false;
      }
    },
  },
});
</script>

<style>
.navbar {
  display: flex;
  justify-content: space-between;
  background-color: #333;
  color: white;
  padding: 10px;
}

.nav-item {
  margin: 0 10px;
}

.dropdown-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
