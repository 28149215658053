<template>
  <div class="mb-1">
    <div class="text-left ml-1">
      <label class="font-bold">Specialisering</label>
    </div>
    <select
      :disabled="isDisabled"
      @change="onSpecSelected"
      :value="selectedSpecCode"
      class="w-full p-1 text-lg rounded-lg bg-white border-2 border-black hover:border-blue-600 focus:ring-blue-500 focus:border-blue-600"
    >
      <option disabled value="" v-if="!selectedSpecCode">
        {{ "Select Spec..." }}
      </option>

      <option
        v-for="spec in dropDownData"
        :key="spec.specialisationCode"
        :value="spec.specialisationCode"
      >
        {{ spec.specialisation_sv }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { mapActions } from "vuex";
import { defineComponent, PropType } from "vue";
import { Spec } from "@/services/typeHolder";

export default defineComponent({
  name: "dropdownMenu",
  props: {
    dropDownData: {
      required: true,
      type: Array as PropType<Spec[]>,
    },
    selectedSpec: {
      type: Object as PropType<Spec>,
    },
    isDisabled: Boolean,
  },

  computed: {
    selectedSpecCode(): Spec | string {
      // Simply reflects the current program code for display purposes
      return this.selectedSpec ? this.selectedSpec.specialisationCode : "";
    },
  },
  methods: {
    ...mapActions(["setSelectedSpec"]),
    onSpecSelected(event: Event) {
      const target = event.target as HTMLSelectElement;
      // this.selectedSpec = target.value;
      const selectedSpec = this.dropDownData.find(
        (spec) => spec.specialisationCode === target.value
      );
      if (selectedSpec) {
        this.setSelectedSpec(selectedSpec);
      }
    },
  },
});
</script>

<style></style>
