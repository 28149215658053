import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-grow mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterContainer = _resolveComponent("FilterContainer")!
  const _component_CourseList = _resolveComponent("CourseList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FilterContainer),
    _createVNode(_component_CourseList, {
      tableData: _ctx.courseState.sortedCourses
    }, null, 8, ["tableData"])
  ]))
}