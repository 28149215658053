<template>
  <div class="relative" ref="dropdown">
    <button
      @click="toggleDropdown"
      class="relative pr-10 pl-1 text-lg rounded-lg bg-white border-2 border-gray-600 hover:border-blue-600 focus:border-blue-600"
    >
      Kategori
    </button>
    <svg
      class="absolute right-2 top-1/2 transform -translate-y-1/2"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path
        fill-rule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clip-rule="evenodd"
      />
    </svg>
    <div
      v-if="showDropdown"
      class="absolute w-full mt-1 bg-white border border-gray-400 rounded-lg shadow-lg z-10"
      :style="{ width: dropdownWidth }"
      ref="dropdownMenu"
    >
      <div
        v-for="[label, value] of dropDownData"
        :key="label"
        class="px-4 py-2 flex items-center dropdown-item"
      >
        <input
          type="checkbox"
          :checked="value"
          @change="updateCategoryFilter(label as string)"
          class="mr-2"
        />
        {{ label }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "dropdownMenu",
  ref: "dropdown",
  props: {
    dropDownData: {
      type: Object as PropType<Map<string, boolean>>,
      required: true,
    },
  },
  data() {
    return {
      showDropdown: false,
      dropdownWidth: "auto",
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      this.$nextTick(() => {
        if (this.showDropdown) {
          this.adjustDropdownWidth();
        }
      });
    },
    adjustDropdownWidth() {
      // Ensure the dropdown menu element is available
      if (this.$refs.dropdownMenu) {
        const dropdownMenuElement = this.$refs.dropdownMenu as HTMLElement;
        const width = `${dropdownMenuElement.offsetWidth}px`;
        this.dropdownWidth = width;
      }
    },
    updateCategoryFilter(label: string) {
      this.$store.state.courseState.toggleCategory(label);
    },
    handleClickOutside(event: MouseEvent) {
      const target = event.target as Node;
      const dropdown = this.$refs.dropdown as HTMLElement;
      if (!dropdown.contains(target)) {
        this.showDropdown = false;
      }
    },
  },
});
</script>

<style>
.dropdown-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
