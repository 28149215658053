import { toRaw } from "vue";
import { finalLayout } from "./displayLogic";
import ProgressHold from "./progressHolder";
import {
  CourseObject,
  DisplaySelected,
  Program,
  SelectedCourses,
  Spec,
} from "./typeHolder";

export default class Specialization {
  program: Program;
  spec: Spec | null = null;
  unsortedCourses: CourseObject[] = [];
  selectedCourses: SelectedCourses = { "År 4": [], "År 5": [] };
  schedule: DisplaySelected = { "År 4": [[]], "År 5": [[]] };
  progress = new ProgressHold();

  constructor(program: Program) {
    this.program = program;
  }


  switchProgram(program: Program){
    this.program = program;
    this.spec = null;

    // const copiedCourses: SelectedCourses = JSON.parse(JSON.stringify(this.selectedCourses));

    // // Step 2: Clear the original selectedCourses
    // Object.keys(this.selectedCourses).forEach(year => {
    //   this.selectedCourses[year] = [];
    // });

    // Step 3: Iterate through the copied courses and process each one
    Object.entries(this.selectedCourses).forEach(([year, courses]) => {
      courses.forEach(course => {
        
        this.removeCourse(course)
        console.log("WADAPA")
        this.addCourse(course, year);
      });
    });
    this.calculateProgress();

  }

  setSpec(spec: Spec): void {
    this.spec = spec;
    // Update progress if courses are selected before spec was choosen.
    this.calculateProgress();
  }

  setSpecProgramCourses(sortedCourses: CourseObject[]): void {
    this.unsortedCourses = sortedCourses;
  }

  addCourse(course: CourseObject, year: string): void {
    this.selectedCourses[year].push(course);
    this.setSchedule();
    // IF THIS IS PLACED HERE AN NOT AT THE TOP, THE Course will be placed at the Lp of the selected course
    // , but progress will be calculated from the course returned from courseBelonging.
    const changeCourse = this.courseBelonging(course);
    this.updateProgress(changeCourse, "add");
  }

  removeCourse(course: CourseObject): void {
    course.selected = false;
    for (const year in this.selectedCourses) {
      this.selectedCourses[year] = this.selectedCourses[year].filter(
        (selectedCourse) => selectedCourse.courseCode !== course.courseCode
      );
    }

    this.setSchedule();
    const changeCourse = this.courseBelonging(course);
    this.updateProgress(changeCourse, "remove");
  }

  setSchedule(): void {
    this.schedule = finalLayout(this.selectedCourses);
  }

  updateProgress(course: CourseObject, operation: string) {
    this.progress.updateProgress(this.program, this.spec, course, operation);
  }

  courseBelonging(course: CourseObject): CourseObject {
    let returnCourse: CourseObject = course;
    let bestMatch: Boolean = false;

    if (this.spec) {
      //Null safety
      for (const programCourse of this.unsortedCourses) {
        if (programCourse.courseCode === course.courseCode) {
          if (!bestMatch) {
            returnCourse = programCourse;
          }

          if (
            programCourse.specialisationCode === this.spec.specialisationCode
          ) {
            bestMatch = true;
          }
        }
      }
    }
    return returnCourse;
  }

  calculateProgress() {
    this.progress.clearProgress();
    Object.values(this.selectedCourses).forEach((year) => {
      year.forEach((selectedCourse) => {
        this.updateProgress(selectedCourse, "add");
      });
    });
  }
}
