 import { CourseObject, DisplaySelected, SelectedCourses } from ".//typeHolder";

function uppdateOffset(newCourseRow: CourseObject[]): void {
  let lastEndpoint = 1;
  newCourseRow.forEach((course) => {
    course.timePlans.offset = course.timePlans.LP - lastEndpoint;
    lastEndpoint = course.timePlans.LP + course.timePlans.duration;
  });
}

function rearrangeRow(subList: CourseObject[], newCourse: CourseObject) {
  let oldValue = 0;
  let newValue = newCourse.timePlans.duration;
  const newCourseRow = [newCourse];

  const removables: CourseObject[] = [];
  const start = newCourse.timePlans.LP;
  const end = start + newValue - 1;

  subList.forEach((course) => {
    oldValue += course.timePlans.duration;
    if (course.timePlans.LP + course.timePlans.duration - 1 < start) {
      newCourseRow.unshift(course);
      newValue += course.timePlans.duration;
    } else if (course.timePlans.LP > end) {
      newCourseRow.push(course);
      newValue += course.timePlans.duration;
    } else {
      removables.push(course);
    }
  });
  if (oldValue < newValue) {
    uppdateOffset(newCourseRow);
    return [newCourseRow, removables];
  }
  return null;
}


function newYearLayout(courseList: CourseObject[][], newCourse: CourseObject) : CourseObject[][] {
  const newLayout = [...courseList];
  let placeCourse = [newCourse];
  while (placeCourse.length > 0) {
    let placed = false;
    const courseToPlace = placeCourse.pop()!;
    for (let i = 0; i < newLayout.length; i++) {
      const rowResult = rearrangeRow(newLayout[i], courseToPlace);
      if (rowResult != null) {
        newLayout[i] = rowResult[0];
        const removables = rowResult[1];
        if (removables.length > 0) {
          placeCourse = placeCourse.concat(removables);
        }
        placed = true;
        break;
      }
    }
    if (!placed) {
      courseToPlace.timePlans.offset = courseToPlace.timePlans.LP - 1;
      newLayout.push([courseToPlace]);
    }
  }
  return newLayout;
}

export function finalLayout(selectedCourses: SelectedCourses) : DisplaySelected {
  const newLayout: DisplaySelected  = {};
  for(const year in selectedCourses){
    let placedCourses: CourseObject[][] = [];
    selectedCourses[year].forEach((newCourse) => {
      const copy = [...placedCourses]
      placedCourses = newYearLayout(copy, newCourse);
    });
    newLayout[year] = placedCourses;
  }
  return newLayout;
}
