import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "flex m-5" }
const _hoisted_3 = {
  class: "w-1/4 pr-10",
  style: {"padding-right":"60px"}
}
const _hoisted_4 = { class: "flex-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopNavbar = _resolveComponent("TopNavbar")!
  const _component_ChooseProgram = _resolveComponent("ChooseProgram")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_FindCourses = _resolveComponent("FindCourses")!
  const _component_ScheduleGrid = _resolveComponent("ScheduleGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopNavbar, { dropDownData: _ctx.myList }, null, 8, ["dropDownData"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ChooseProgram),
        _createVNode(_component_ProgressBar, { tableData: _ctx.progress }, null, 8, ["tableData"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_FindCourses),
        _createVNode(_component_ScheduleGrid, { gridData: _ctx.schedule }, null, 8, ["gridData"])
      ])
    ])
  ]))
}