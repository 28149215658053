import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center mb-4" }
const _hoisted_2 = { class: "mr-4" }
const _hoisted_3 = { class: "mr-4" }
const _hoisted_4 = { class: "mr-4" }
const _hoisted_5 = { class: "mr-4" }
const _hoisted_6 = { class: "mr-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_LpSearchDropdown = _resolveComponent("LpSearchDropdown")!
  const _component_LevelDropdown = _resolveComponent("LevelDropdown")!
  const _component_CategoryDropdown = _resolveComponent("CategoryDropdown")!
  const _component_ProgramDropdown = _resolveComponent("ProgramDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SearchBar)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_LpSearchDropdown, {
        dropDownData: _ctx.courseState.lps
      }, null, 8, ["dropDownData"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_LevelDropdown, {
        dropDownData: _ctx.courseState.cycle
      }, null, 8, ["dropDownData"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_CategoryDropdown, {
        dropDownData: _ctx.courseState.categories
      }, null, 8, ["dropDownData"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_ProgramDropdown, {
        dropDownData: _ctx.courseState.programs,
        selectedProgram: _ctx.selectedProgram
      }, null, 8, ["dropDownData", "selectedProgram"])
    ])
  ]))
}