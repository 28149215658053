import { fetchCourses } from "./apiRequest";
import Specializationlization from "./specialization";
import {
  CategorizedCourses,
  CourseObject,
  Program,
  SelectedCourses,
} from "./typeHolder";

export default class CourseState {
  programs: Program[] = [];
  courses: CourseObject[] = [];

  searchProgram: Program | null = null;
  spec: Specializationlization | null = null;

  sortedCourses: CategorizedCourses = {};
  lps: boolean[] = [true, true, true, true];
  cycle: { [key: string]: boolean } = { G1: true, G2: true, A: true };
  duration: { [key: string]: boolean } = {
    "1": true,
    "2": true,
    "3": true,
    "4": true,
  };
  categories: Map<string, boolean> = new Map();

  setSearchProgram(program: Program | null): void {
    this.searchProgram = program;
  }

  async fetchStateCourses() {
    // Promise<CourseObject[]>
    //Null safety
    if (this.searchProgram) {
      this.courses = await fetchCourses(this.searchProgram.programmeCode);
    }

    // if (this.searchProgram) {
    //   unsortedCourses =
    //   this.courses = unsortedCourses;
    // } else {
    //   this.courses = [];
    // }
    this.nullCategories();
    this.setCategories();
    this.setSortedCourses();

    // return unsortedCourses;
  }

  setCategories(): void {
    for (const course of this.courses) {
      const category = this.categorizeCourse(course);
      if (!this.categories.has(category)) {
        // console.log(category);
        this.categories.set(category, true);
      }
    }
  }

  setSortedCourses(): void {
    const courseCpoy = JSON.parse(JSON.stringify(this.courses));
    const filteredCourses = this.filter(courseCpoy); // removes all courses which does not match with filter.
    // Set selected true for courses with same courseCode
    const modifiedSelected = this.setSelectedProperty(filteredCourses);

    const groupedCourses = this.groupCoursesByCategory(modifiedSelected); // Groups each course into a specific category
    this.sortCoursesWithinCategories(groupedCourses); // Sorts the courses within each category
    this.sortedCourses = this.categoryFilter(groupedCourses); // Removes the categories according to the filter.
  }

  nullCategories(): void {
    this.categories.clear();
  }

  categorizeCourse(course: CourseObject): string {
    switch (course.groupId) {
      case "O1":
      case "O2":
      case "O3":
        return "Grundblock";
      case "V":
        return "Valfria Kurser";
      case "E":
        return "Externt Valfria Kurser";
      case "exjobb":
        return course.cycle === 'A' ? "Exjobb" : "Grundblock";
      default:
        return course.specialisation_sv;
    }
  }

  toggleLp(newLp: number, selectedCourses: SelectedCourses | null): void {
    this.lps[newLp] = !this.lps[newLp];
    this.setSortedCourses();
  }

  toggleLevel(level: string, selectedCourses: SelectedCourses | null): void {
    this.cycle[level] = !this.cycle[level];
    this.setSortedCourses();
  }

  toggleCategory(
    category: string,
    selectedCourses: SelectedCourses | null
  ): void {
    this.categories.set(category, !this.categories.get(category));
    this.setSortedCourses();
  }

  levelFilter(courseCycle: String): boolean {
    for (const key in this.cycle) {
      if (this.cycle[key]) {
        if (courseCycle == key) {
          return true;
        }
      }
    }
    return false;
  }

  lpFilter(courseStart: number): boolean {
    for (let i = 0; i < this.lps.length; i++) {
      if (this.lps[i]) {
        if (courseStart == i + 1) {
          return true;
        }
      }
    }
    return false;
  }

  filter(programCourses: CourseObject[]): CourseObject[] {
    const tempCourse = [];

    for (const course of programCourses) {
      // Undantag för exjobb eftersom dem inte går över någon läsperiod enligt "timePlans" på LTH
      if (course.groupId === "exjobb") {
        tempCourse.push(course);
      } else if (
        this.levelFilter(course.cycle) &&
        this.lpFilter(course.timePlans.LP)
      ) {
        tempCourse.push(course);
      }
    }
    return tempCourse;
  }

  setSelectedProperty(filteredCourses: CourseObject[]): CourseObject[] {
    const filteredCoursesCopy = [...filteredCourses];
    let selectedCourses: SelectedCourses | null = null;
    // null safety
    if (this.spec) {
      selectedCourses = this.spec.selectedCourses;
    }
    // Null safety
    if (selectedCourses) {
      filteredCoursesCopy.forEach((course) => {
        const courseExists = Object.values(selectedCourses!).some((year) =>
          year.some(
            (selectedCourse) => selectedCourse.courseCode === course.courseCode
          )
        );
        course.selected = courseExists;
      });
    }

    return filteredCoursesCopy;
  }

  categoryFilter(sortedCourses: CategorizedCourses): CategorizedCourses {
    const filteredCourses: CategorizedCourses = {};
    for (const [categoryKey, isEnabled] of this.categories) {
      if (isEnabled) {
        filteredCourses[categoryKey] = sortedCourses[categoryKey];
      }
    }
    return filteredCourses;
  }

  groupCoursesByCategory(programCourses: CourseObject[]): CategorizedCourses {
    const categoriezedCourses: CategorizedCourses = {};

    for (const course of programCourses) {
      const courseKey: string = this.categorizeCourse(course);
      if (categoriezedCourses[courseKey] == null) {
        categoriezedCourses[courseKey] = [];
      }
      categoriezedCourses[courseKey].push(course);
    }

    return categoriezedCourses;
  }

  private levelOrder(level: String) {
    switch (level) {
      case "G1":
        return 1;
      case "G2":
        return 2;
      case "A":
        return 3;
      default:
        return 1; // just for error safety
    }
  }

  sortCoursesWithinCategories(groupedCourses: CategorizedCourses): void {
    Object.keys(groupedCourses).forEach((category) => {
      groupedCourses[category].sort((a, b) => {
        if (a.specialisation_sv === b.specialisation_sv) {
          if (a.timePlans.LP === b.timePlans.LP) {
            return this.levelOrder(a.cycle) - this.levelOrder(b.cycle);
          }
          return a.timePlans.LP - b.timePlans.LP;
        }
        return a.specialisation_sv.localeCompare(b.specialisation_sv);
      });
    });
  }

  uppdateSameCourseCode(course: CourseObject) {
    for (const category in this.sortedCourses) {
      for (const programCourse of this.sortedCourses[category]) {
        if (programCourse.courseCode === course.courseCode) {
          if (programCourse != course) {
            programCourse.selected = !programCourse.selected;
          }
        }
      }
    }
  }
}
