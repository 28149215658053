import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "relative",
  ref: "dropdown"
}
const _hoisted_2 = {
  key: 0,
  class: "absolute w-full mt-1 bg-white border border-gray-400 rounded-lg shadow-lg z-10"
}
const _hoisted_3 = ["checked", "onChange"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args))),
      class: "pr-1 pl-1 text-lg w-full rounded-lg bg-white border-2 border-gray-600 hover:border-blue-600 focus:border-blue-600"
    }, " Läsperiod "),
    (_ctx.showDropdown)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropDownData, (value, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "px-4 py-2 flex items-center"
            }, [
              _createElementVNode("input", {
                type: "checkbox",
                checked: value,
                onChange: ($event: any) => (_ctx.updateLpFilter(index)),
                class: "mr-2"
              }, null, 40, _hoisted_3),
              _createTextVNode(" LP " + _toDisplayString(index + 1), 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 512))
}