<template>
    <div class="flex-grow mb-4">
      <FilterContainer></FilterContainer>
      <CourseList :tableData="courseState.sortedCourses"></CourseList>
    </div>
</template>

<script lang="ts">
import { defineComponent} from 'vue';
import { mapGetters } from "vuex";
import CourseList from "./CourseList.vue";
import FilterContainer from "./SearchContainer/FilterContainer.vue";

export default defineComponent({
  name: "FindCourses",
  components: {
    CourseList,
    FilterContainer
},
  computed: {
    ...mapGetters(["courseState",]),
  },
});
</script>

<style></style>
