<template>
  <div id="app">
    <TopNavbar :dropDownData="myList"></TopNavbar>
    <div class="flex m-5">
      <div class="w-1/4 pr-10" style="padding-right: 60px">
        <ChooseProgram></ChooseProgram>
        <ProgressBar :tableData="progress"></ProgressBar>
      </div>
      <div class="flex-grow">
        <FindCourses></FindCourses>
        <ScheduleGrid :gridData="schedule"></ScheduleGrid>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import { defineComponent } from "vue";
import TopNavbar from "./components/TopNavbar.vue";
import ChooseProgram from "./components/ChooseProgram/ChooseProgram.vue";
import FindCourses from "./components/FindCourses/FindCourses.vue";
import ScheduleGrid from "./components/Schedule/ScheduleGrid.vue";
import ProgressBar from "./components/ProgressTable/ProgressBar.vue";

export default defineComponent({
  name: "App",
  components: {
    TopNavbar,
    ChooseProgram,
    ProgressBar,
    ScheduleGrid,
    FindCourses,
  },
  computed: {
    ...mapGetters(["schedule", "progress", "myList"]),
  },
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
