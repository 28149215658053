<template>
  <div
    class="mb-2 border-2 p-2 rounded-md"
    :class="{
      'opacity-50': specFullyInitilized,
      'pointer-events-none': specFullyInitilized,
    }"
  >
    <label class="font-bold text-2xl">
      Välj <span class="text-blue-500">Specialisering</span></label
    >
    <programDropdown
      :dropDownData="programs"
      :selectedProgram="selectedProgram"
      :isDisabled="specFullyInitilized"
    />
    <specDropdown 
      :dropDownData="specs" 
      :selectedSpec="selectedSpec"
      :isDisabled="specFullyInitilized"
      />
  </div>
  <div v-if="specFullyInitilized" class="flex flex-col items-center space-y-2">
    <button class="btn-submit" @click="editSpec()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
        />
      </svg>
      <p class="pl-2">Specialisering</p>
    </button>

    <button class="btn-submit" @click="crateNewSpec()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>

      <p class="pl-2">Specialisering</p>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapGetters, mapActions } from "vuex";
import programDropdown from "./programDropdown.vue";
import specDropdown from "./specDropdown.vue";

export default defineComponent({
  name: "CooseProgram",
  data() {
    return {
      specFullyInitilized: false,
    };
  },

  components: {
    programDropdown,
    specDropdown,
  },

  computed: {
    ...mapGetters(["programs", "specs", "currentSpecialization"
      , "selectedProgram", "selectedSpec"]),
  },

  methods: {
    ...mapActions(["fetchAllPrograms", "setSpecs", "resetState",]),
    editSpec() {
      this.specFullyInitilized = false;
    },
    crateNewSpec(){
      this.resetState();
      this.specFullyInitilized = false;      
    },
  },
  mounted() {
    this.fetchAllPrograms();
  },

  watch: {
    "$store.state.currentSpecialization.spec"(spec) {
      if(spec != null){
        this.specFullyInitilized = true;
      }else{
        this.specFullyInitilized = false;
      }
      
    },
  },
});
</script>

<style scoped>
.btn-submit {
  @apply bg-blue-500 text-black font-bold w-44 h-10 flex items-center justify-center py-2 px-4 rounded-md;
  @apply transition duration-100 ease-in-out hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50;
  @apply transform duration-150 ease-in-out active:scale-95;
}
</style>
