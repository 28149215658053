//import PortalVue from 'portal-vue'
//import { createPopper } from '@popperjs/core';

import { createApp } from 'vue'
import App from './App.vue'
import './main.css'
import { Row, Column } from 'vue-grid-responsive';

import store from './services/store'



const app = createApp(App);

app.component('gridRow', Row);
app.component('gridCol', Column);
// app.component('hidden', Hidden);

app.use(store)

app.mount('#app')

