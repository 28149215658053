import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "relative w-full max-w-xs",
  ref: "dropdown"
}
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 0,
  disabled: "",
  value: ""
}
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("select", {
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
      value: _ctx.selectedProgramCode,
      class: "w-full p-1 text-lg rounded-lg bg-white border-2 border-gray-600 hover:border-blue-600 focus:ring-blue-500 focus:border-blue-600"
    }, [
      (!_ctx.selectedProgramCode)
        ? (_openBlock(), _createElementBlock("option", _hoisted_3, _toDisplayString("Select program")))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropDownData, (program) => {
        return (_openBlock(), _createElementBlock("option", {
          key: program.programmeCode,
          value: program.programmeCode
        }, _toDisplayString(program.programme_sv), 9, _hoisted_4))
      }), 128))
    ], 40, _hoisted_2)
  ], 512))
}