<template>
  <div class="mb-2">
    <div class="text-left ml-1">
      <label class="font-bold">Program</label>
    </div>
    <select
      :disabled="isDisabled"
      @change="updateValue"
      :value="selectedProgramCode"
      class="w-full p-1 text-lg rounded-lg bg-white border-2 border-black hover:border-blue-600 focus:ring-blue-500 focus:border-blue-600"
    >
      <option disabled value="" v-if="!selectedProgramCode">
        {{ "Select program..." }}
      </option>

      <option
        v-for="program in dropDownData"
        :key="program.programmeCode"
        :value="program.programmeCode"
      >
        {{ program.programme_sv }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { mapActions } from "vuex";
import { defineComponent, PropType } from "vue";
import { Program } from "../../services/typeHolder";

export default defineComponent({
  name: "dropdownMenu",
  props: {
    dropDownData: {
      type: Array as PropType<Program[]>,
      required: true,
    },
    selectedProgram: {
      type: Object as PropType<Program>,
    },
    isDisabled: Boolean,
  },
  computed: {
    selectedProgramCode(): string {
      // Simply reflects the current program code for display purposes
      return this.selectedProgram ? this.selectedProgram.programmeCode : "";
    },
  },
  methods: {
    ...mapActions(["createSpecialization","switchProgram"]),
    updateValue(event: Event) {
      const target = event.target as HTMLSelectElement;
      const selectedProgram = this.dropDownData.find(
        (program) => program.programmeCode === target.value
      );
      this.selectedProgram ? this.switchProgram(selectedProgram): this.createSpecialization(selectedProgram)
      
      // this.switchProgram(selectedProgram);
      // !this.createSpecialization(selectedProgram);
    },
  },
});
</script>

<style></style>
