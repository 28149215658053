<template>
  <div class="relative w-full max-w-xs" ref="dropdown">
    <select
      @change="updateValue"
      :value="selectedProgramCode"
      class="w-full p-1 text-lg rounded-lg bg-white border-2 border-gray-600 hover:border-blue-600 focus:ring-blue-500 focus:border-blue-600"
    >
      <option disabled value="" v-if="!selectedProgramCode">
        {{ "Select program" }}
      </option>
      <option
        v-for="program in dropDownData"
        :key="program.programmeCode"
        :value="program.programmeCode"
      >
        {{ program.programme_sv }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { mapActions } from "vuex";
import { defineComponent, PropType } from "vue";
import { Program } from "../../../services/typeHolder";

export default defineComponent({
  name: "dropdownMenu",
  props: {
    dropDownData: {
      type: Array as PropType<Program[]>,
      required: true,
    },
    selectedProgram: {
      type: Object as PropType<Program>,
      default: () => ({}),
    },
  },
  computed: {
    selectedProgramCode: {
      get(): string {
        return this.selectedProgram?.programmeCode || "";
      },
      set(code: string) {
        const program = this.dropDownData.find(
          (program) => program.programmeCode === code
        );
        this.updateValueByProgram(program);
      },
    },
  },
  methods: {
    ...mapActions(["setSearchProgram"]),
    updateValue(event: Event) {
      const target = event.target as HTMLSelectElement;
      this.selectedProgramCode = target.value;
    },
    updateValueByProgram(program: Program | undefined) {
      if (program) {
        this.setSearchProgram({ program });
      }
    },
  },
  watch: {
    // selectedProgram(newProgram, oldProgram) {
    //   if (newProgram !== oldProgram && newProgram) {
    //     this.updateValueByProgram(newProgram);
    //   }
    // },
  },
});
</script>

<style></style>
