import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "text-left ml-1" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = {
  key: 0,
  class: "relative w-full bg-gray-200 rounded-full h-2.5 mb-4"
}
const _hoisted_5 = {
  key: 1,
  class: "bg-yellow-500 h-2.5 rounded-full absolute",
  style: {"width":"33.33%"}
}
const _hoisted_6 = {
  key: 1,
  class: "w-full bg-gray-200 rounded-full h-2.5 mb-4"
}
const _hoisted_7 = {
  key: 2,
  class: "w-full bg-gray-200 rounded-full h-2.5 mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (category, key) => {
    return (_openBlock(), _createElementBlock("div", {
      key: key,
      class: "mb-2 border-2 p-2 rounded-md"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category, (requirement, key) => {
        return (_openBlock(), _createElementBlock("div", {
          key: key,
          class: "mb-1 text-base font-medium"
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("label", _hoisted_3, _toDisplayString(key), 1)
            ]),
            _createElementVNode("div", null, _toDisplayString(requirement.status + "/" + requirement.boundary), 1)
          ]),
          (key == 'Max Valfri hp')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: _normalizeClass(["bg-blue-600 h-2.5 rounded-full absolute", {
                            'bg-green-700': requirement.status >= requirement.boundary
                            }]),
                  style: _normalizeStyle({ width: _ctx.calculateWidth(requirement.status, requirement.boundary) })
                }, null, 6),
                (category['Max Externt Valfria HP'].status <= category['Max Externt Valfria HP'].boundary)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "bg-green-500 h-2.5 rounded-full absolute",
                      style: _normalizeStyle({ width: _ctx.calculateWidth(category['Max Externt Valfria HP'].status, requirement.boundary) })
                    }, null, 4))
                  : (_openBlock(), _createElementBlock("div", _hoisted_5))
              ]))
            : (key == 'Max Externt Valfria HP')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["bg-green-500 h-2.5 rounded-full", {
                    'bg-yellow-500': requirement.status > requirement.boundary
                    }]),
                    style: _normalizeStyle({ width: _ctx.calculateWidth(requirement.status, requirement.boundary) })
                  }, null, 6)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["bg-blue-600 h-2.5 rounded-full", {
                            'bg-green-500': requirement.status >= requirement.boundary 
                        }]),
                    style: _normalizeStyle({ width: _ctx.calculateWidth(requirement.status, requirement.boundary) })
                  }, null, 6)
                ]))
        ]))
      }), 128))
    ]))
  }), 128))
}