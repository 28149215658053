import { getSpecs } from "./apiRequest";
import CourseState from "./courseState";
import Specialization from "./specialization";
import {
  DisplaySelected,
  Program,
  ProgressHolder,
  SelectedCourses,
  Spec,
} from "./typeHolder";

export default class State {
  courseState = new CourseState();
  specs: Spec[] = [];
  currentSpecialization: Specialization | null = null;
  specializations: Specialization[] = [];

  //SETTERS
  setSpecs() {
    this.specs = getSpecs(this.courseState.courses);
  }

  createSpecialization(program: Program) {
    const specialization = new Specialization(program);
    this.currentSpecialization = specialization;
    this.courseState.spec = specialization;

    this.specializations.push(specialization);
  }

  setSelectedSpec(value: Spec): void {
    if (this.currentSpecialization) {
      this.currentSpecialization.setSpec(value);
    }
  }

  setCurrentSpec(spec: Specialization): void {
    this.currentSpecialization = spec;
    this.courseState.spec = spec;
    this.courseState.setSearchProgram(spec.program);
  }

  setSchedule(): void {
    if (this.currentSpecialization) {
      this.currentSpecialization.setSchedule();
    }
  }

  resetState(): void {
    this.currentSpecialization = null;
    this.courseState.setSearchProgram(null);
    this.courseState.spec = null;
  }

  //GETTERS

  getSelectedProgram(): Program | null {
    if (this.currentSpecialization) {
      return this.currentSpecialization.program;
    }
    return null;
  }

  getSpecializationSpec(): Spec | null {
    if (this.currentSpecialization) {
      return this.currentSpecialization.spec;
    }
    return null;
  }

  getProgress(): ProgressHolder | null {
    if (this.currentSpecialization) {
      return this.currentSpecialization.progress.progressHolder;
    }
    return null;
  }

  getSelectedCourses(): SelectedCourses | null {
    if (this.currentSpecialization) {
      return this.currentSpecialization.selectedCourses;
    }
    return null;
  }

  getSchedule(): DisplaySelected | null {
    if (this.currentSpecialization) {
      return this.currentSpecialization.schedule;
    }
    return null;
  }
}
