<template>
  <div class="relative" ref="dropdown">
    <button
      @click="toggleDropdown"
      class="pr-1 pl-1 text-lg w-full rounded-lg bg-white border-2 border-gray-600 hover:border-blue-600 focus:border-blue-600"
    >
      Kursnivå
    </button>
    <div
      v-if="showDropdown"
      class="absolute w-full mt-1 bg-white border border-gray-400 rounded-lg shadow-lg z-10"
    >
      <div
        v-for="[label, value] of dropDownData"
        :key="label"
        class="px-4 py-2 flex items-center"
      >
        <input
          type="checkbox"
          :checked="value"
          @change="updateLevelFilter(label)"
          class="mr-2"
        />
        {{ label }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent} from 'vue';
// import { mapGetters } from "vuex";

export default defineComponent({
  name: "dropdownMenu",
  ref: "dropdown",
  props: {
    dropDownData: {
      type: Object as PropType<Map<string, boolean>>,
      required: true,
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    updateLevelFilter(label: string) {
      this.$store.state.courseState.toggleLevel(label);
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    handleClickOutside(event: MouseEvent) {
      const target = event.target as Node;
      const dropdown = this.$refs.dropdown as  HTMLElement;
      if (!dropdown.contains(target)) {
        this.showDropdown = false;
      }
    },
  },
});
</script>

<style></style>
