<template>
  <div class="widget relative flex rounded-lg p-2 bg-gray-200 mt-1 mb-1 mr-1 max-w-full" :class="{
                        'bg-green-400 transition-colors duration-100': course.completed
                    }">
    <div class="max-w-full flex">
      <label class="mr-5 truncate max-w-[65%]">{{ course.name_sv }}</label>
    </div>

    <div class="flex-shrink-0 absolute inset-y-1 right-1">
      <label class="m-2 flex-grow flex-shrink-0">{{
        course.credits
      }}</label>
      <button
        class="rounded bg-blue-500 
        hover:bg-blue-600 text-white p-1 m-1 transition-colors duration-100 
        active:bg-blue-700 text-white p-1 m-1 transition transform duration-150 ease-in-out active:scale-95"
        @click="completeButton(course)"
      >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
      </svg>
      </button>
      <button
        class="rounded bg-red-400 
        hover:bg-red-500 text-white p-1 m-1 transition-colors duration-100
        active:bg-red-500 text-white p-1 m-1 transition transform duration-150 ease-in-out active:scale-95"
        @click="removeButton(course)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="3"
          stroke="currentColor"
          class="w-4 h-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      
    </div>
  </div>
</template>

<script lang="ts">
import { CourseObject } from '@/services/typeHolder';
import { defineComponent, PropType } from 'vue';
import { mapActions } from "vuex";
export default defineComponent({
  name: "CourseWidget",
  props: {
    course: {
      type: Object as PropType<CourseObject>,
      required: true,
    },
  },
  methods: {
    ...mapActions(["removeCourse"]),

    removeButton(course: CourseObject) {
      this.removeCourse({course});
    },
    completeButton(course: CourseObject){
      course.completed = !course.completed;
    }

  },
});
</script>
