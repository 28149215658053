import {
  CourseObject,
  Program,
  TimeObject,
  TimePlans,
  Spec,
} from ".//typeHolder";

// Function to build the API URL dynamically to request the courses for a specific program and academic year.
function buildApiUrl(programmeCode: String, academicYearId: String): string {
  const baseUrl = "https://api.lth.lu.se/lot/courses";
  return `${baseUrl}?programmeCode=${programmeCode}&academicYearId=${academicYearId}`;
}

// Fetches all available programs from the apiURL
export async function fetchPrograms(): Promise<Program[]> {
  const apiUrl = "https://api.lth.lu.se/lot/courses/programmes?kull=false";
  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json(); // This returns a promise
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

// Function to fetch data from the API
async function fetchData(
  programmeCode: String,
  academicYearId: String
): Promise<any> {
  const apiUrl = buildApiUrl(programmeCode, academicYearId);
  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json(); // This returns a promise
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

// Itterates over the timePlan object, which is a nested object from the dictionary recivied from the apiURl for a certian program.
// Returns the neccessary information from the timePlan object such as LP-start, duration, offset(important for the Schedule)
// SOMEWHAT WRONG SOLUTION SINCE ITS ONLY CHECKING THE FIRST TimePlan AND DOES NOT TAKE TWO OR MROE TIMEPLANS INTO CONCIDERATION
function extractTimePlans(timePlans: TimePlans): TimeObject {
  const timeObject: TimeObject = { lps: [], LP: 0, duration: 0, offset: 0 };
  const studyPeriods = timePlans[0]["studyPeriods"];

  let exjobbCounter = 0;

  for (const lp of studyPeriods) {
    if (lp == null) {
      timeObject.lps.push(false);
      exjobbCounter++;
    } else {
      timeObject.duration += 1; //
      timeObject.lps.push(true);
    }
  }
  if (exjobbCounter == 4) {
    timeObject.lps[2] = true;
    timeObject.lps[3] = true;
    timeObject.duration = 2;
  }
  timeObject.LP = timeObject.lps.indexOf(true) + 1;
  return timeObject;
}

// Trims a specific course such that only information in "keysToKeep" are saved for each course.
function trimObject(courseObject: any): CourseObject {
  const keysToKeep = [
    "courseCode",
    "name_sv",
    "groupId",
    "programmeCode",
    "specialisationCode",
    "specialisation_sv",
    "specialisation_en",
    "credits",
    "cycle",
    "timePlans",
  ];
  const trimmedCourseObject: Partial<CourseObject> = {};

  //Itterates over the dictionary. Note that timePlans is a nested object, therefore extractTimePlans is called.
  keysToKeep.forEach((key) => {
    if (key in courseObject) {
      if (key === "timePlans") {
        // console.log(courseObject.courseCode)
        trimmedCourseObject[key] = extractTimePlans(courseObject[key]);
      } else {
        trimmedCourseObject[key as keyof CourseObject] =
          courseObject[key as keyof Partial<CourseObject>];
      }
    }
  });
  trimmedCourseObject["selected"] = false;
  trimmedCourseObject["completed"] = false;

  return trimmedCourseObject as CourseObject;
}

// trimObject is preformed on each course in programCourses
function trimProgramCourses(programCourses: any): CourseObject[] {
  if (!programCourses || programCourses.length === 0) {
    console.error("No data received from fetchData");
    return [];
  }
  return programCourses.map((obj: any) => trimObject(obj));
}

export async function fetchCourses(programmeCode: String) {
  const programCourses = await fetchData(programmeCode, "23_24");
  const trimmedData = trimProgramCourses(programCourses);
  return trimmedData;
}

export function getSpecs(unsortedCourses: CourseObject[]): Spec[] {
  // const unsortedCourses = await fetchCourses(programmeCode);
  const uniqueSpecs = new Map<string, Spec>();

  for (const course of unsortedCourses) {
    if (
      !uniqueSpecs.has(course.specialisationCode) &&
      !(course.specialisationCode == "general")
    ) {
      const newSpec: Spec = {
        specialisationCode: course.specialisationCode,
        specialisation_sv: course.specialisation_sv,
        specialisation_en: course.specialisation_en,
      };
      uniqueSpecs.set(course.specialisationCode, newSpec);
    }
  }

  return Array.from(uniqueSpecs.values());
}

// async function writeToConsole() {
//   //let specs = await fetchSpecs("BME")
//   //console.log(specs);
//   let sortCat = await fetchCourses("BME");
//   // const programCourses = await fetchData("BME", "23_24");
//   //console.log(sortCat);
// }

// (async function () {
//   try {
//     await writeToConsole();
//   } catch {
//     console.error("Error occurred:");
//   }
// })();
