import {
  CourseObject,
  Spec,
  Requirement,
  ProgressHolder,
  Program,
} from "./typeHolder";

export default class ProgressHold {
  progressHolder: ProgressHolder = {
    optional: {
      "Max Valfri hp": { status: 0, boundary: 45 },
      "Max Externt Valfria HP": { status: 0, boundary: 15 },
    },
    required: {
      // "Grundblock": { status: 0, boundary: 180 },
      Specialisering: { status: 0, boundary: 45 },
      "Avancerad inom specialisering": { status: 0, boundary: 30 },
      Exjobb: { status: 0, boundary: 30 },
      "Total Avancerad HP": { status: 0, boundary: 45 },
      "Total HP": { status: 0, boundary: 120 },
    },
  };

  clearProgress() {
    Object.keys(this.progressHolder).forEach((type) => {
      Object.keys(this.progressHolder[type]).forEach((category) => {
        this.progressHolder[type][category].status = 0;
      });
    });
  }

  update(hp: number, key: string) {
    let type = "required";
    if (key === "Max Valfri hp" || key === "Max Externt Valfria HP") {
      type = "optional";
    }

    const requierment = this.progressHolder[type][key];

    if (requierment) {
      const updatedRequirement: Requirement = {
        ...requierment,
        status: requierment.status + hp,
      };
      this.progressHolder[type][key] = updatedRequirement;
    } else {
      console.log(key + " not found in progress.");
    }
  }

  updateS(hp: number) {
    this.update(hp, "Specialisering");
    // this.updateTotal(hp);
  }
  updateSA(hp: number) {
    this.update(hp, "Avancerad inom specialisering");
    // this.updateTotal(hp);
    // this.updateTotalA(hp);
  }

  updateO(hp: number) {
    this.update(hp, "Max Valfri hp");
    // // FEL LOGIK. Problematik kring om man lägger till en kurs som överstiger boundry, då ska ändå en del av hpn räknas in
    // if(this.progress["Max Valfri hp"].status < this.progress["Max Valfri hp"].boundary){

    // }
  }

  updateEO(hp: number) {
    this.update(hp, "Max Externt Valfria HP");
  }

  updateExjobb(hp: number) {
    this.update(hp, "Exjobb");
  }

  updateTotalA(hp: number) {
    this.update(hp, "Total Avancerad HP");
  }

  updateTotal(hp: number) {
    this.update(hp, "Total HP");
  }

  summarizeTotal() {
    let totalStatus = 0;

    Object.entries(this.progressHolder).forEach(([type, content]) => {
      Object.entries(content).forEach(([key, value]) => {
        if (
          key === "Specialisering" ||
          key === "Max Valfri hp" ||
          key === "Exjobb"
        ) {
          if (key === "Max Valfri hp") {
            if (value.status > value.boundary) {
              totalStatus += value.boundary;
            } else {
              totalStatus += value.status;
            }
          } else {
            totalStatus += value.status;
          }
        }
      });
    });

    this.progressHolder["required"]["Total HP"].status = totalStatus;
  }

  oProgress(course: CourseObject, courseCredit: number) {
    const O = this.progressHolder["optional"]["Max Valfri hp"];
    let aCredit = courseCredit;
    if (course.cycle === "A") {
      if (O.status <= O.boundary) {
        if (O.status + courseCredit >= O.boundary) {
          aCredit = O.boundary - O.status;
        }
        this.updateTotalA(aCredit);
      }
    }
    this.updateO(courseCredit);
  }

  eoProgress(course: CourseObject, courseCredit: number) {
    const EO = this.progressHolder["optional"]["Max Externt Valfria HP"];
    let oCredit = courseCredit;
    if (EO.status <= EO.boundary) {
      if (EO.status + courseCredit >= EO.boundary) {
        oCredit = EO.boundary - EO.status;
      }
    } else {
      oCredit = 0;
    }
    this.updateEO(courseCredit);
    this.updateO(oCredit);
    if (course.cycle === "A") {
      this.updateTotalA(oCredit);
    }
  }

  optionalProgress(
    program: Program,
    course: CourseObject,
    courseCredit: number
  ) {
    if (course.programmeCode != program.programmeCode || course.groupId === "E") {
      this.eoProgress(course, courseCredit);
    } else {
      this.oProgress(course, courseCredit);
    }
  }

  isRequiered(program: Program, spec: Spec, course: CourseObject) {
    if (course.programmeCode === program.programmeCode ) {
      if (course.groupId === spec.specialisationCode || course.groupId === "exjobb") {
        return true;
      }
    }
    return false;
  }

  requieredProgress(
    
    course: CourseObject,
    courseCredit: number
  ) {
    if (course.groupId === "exjobb" && course.cycle === "A" ) {
      
        this.updateExjobb(courseCredit);
      
      
    } else {
      this.updateS(courseCredit);
      if (course.cycle === "A") {
        this.updateTotalA(courseCredit);
        this.updateSA(courseCredit);
      }
    }
  }

  specLessProgress(course: CourseObject, courseCredit: number) {
    this.updateTotal(courseCredit);
    if (course.cycle === "A") {
      this.updateTotalA(courseCredit);
    }
  }

  updateProgress(
    program: Program,
    spec: Spec | null,
    course: CourseObject,
    operation: string
  ): ProgressHolder {
    //Groundblock - no changes
    if (
      course.groupId == "O1" ||
      course.groupId == "O2" ||
      course.groupId == "O3" ||
      course.groupId == "exjobb" && course.cycle !="A" 
    ) {
      return this.progressHolder;
    }

    // Setting coursecredit  based on operation input
    let courseCredit = Number(course.credits);
    if (operation === "remove") {
      courseCredit = -courseCredit;
    }
    if (spec) {
      if (this.isRequiered(program, spec, course)) {
        this.requieredProgress(course, courseCredit);
      } else {
        this.optionalProgress(program, course, courseCredit);
      }
      this.summarizeTotal();
    } else {
      // Progress withouth Spec choosen.
      this.specLessProgress(course, courseCredit);
    }

    return this.progressHolder;
  }
}
