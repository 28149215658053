

<template>
    <div class="square-container" :class="{ 'bg-blue-500': lps, 'white-border': !lps }">
        <span class="text-white justify-center" v-if="lps">{{ index + 1 }}</span> 
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: "CourseWidget",
  props: {
    lps: {
      type: Boolean,
      required: true,
    },
    index: {
        type: Number,
        required: true,
    }
  },
});
</script>

<style>
.square-container {
  width: 20px; /* Set the size of the square */
  height: 20px; /* Set the size of the square */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 3px;
}

.black-fill {
  background-color: blue-500 ;
  
}

.white-border {
  border: 2px solid grey;
}
</style>