import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "search-bar" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
      placeholder: "Search here...",
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.performSearch && _ctx.performSearch(...args)))
    }, null, 544), [
      [_vModelText, _ctx.searchQuery]
    ]),
    (_ctx.searchResults.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResults, (result, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(result), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}