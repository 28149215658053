<template>
  <div class="flex items-center mb-4">
    <div class="mr-4">
      <SearchBar></SearchBar>
    </div>
    <div class="mr-4">
      <LpSearchDropdown :dropDownData="courseState.lps"></LpSearchDropdown>
    </div>
    <div class="mr-4">
      <LevelDropdown :dropDownData="courseState.cycle"></LevelDropdown>
    </div>
    <div class="mr-4">
      <CategoryDropdown :dropDownData="courseState.categories"></CategoryDropdown>
    </div>
    <div class="mr-4">
      <ProgramDropdown 
        :dropDownData="courseState.programs"
        :selectedProgram="selectedProgram"
      ></ProgramDropdown>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import { defineComponent} from 'vue';
import SearchBar from "./SearchBar.vue";
import LpSearchDropdown from "./LpSearchDropdown.vue";
import LevelDropdown from "./LevelDropdown.vue";
import CategoryDropdown from "./CategoryDropdown.vue";
import ProgramDropdown from "./ProgramDropdown.vue";

export default defineComponent({
  name: "FilterContainer",
  components: {
    SearchBar,
    LpSearchDropdown,
    LevelDropdown,
    CategoryDropdown,
    ProgramDropdown,
  },
  computed: {
    ...mapGetters([ "courseState", "selectedProgram" ]),
  },
});
</script>

<style></style>
