<template>
    <div class="search-bar">
      <input type="text" v-model="searchQuery" placeholder="Search here..." @input="performSearch" />
  
      <!-- Display search results -->
      <ul v-if="searchResults.length">
        <li v-for="(result, index) in searchResults" :key="index">
          {{ result }}
        </li>
      </ul>
    </div>
  </template>
  
  
<script lang="ts">
import { defineComponent} from 'vue';
export default defineComponent({
  data() {
    return {
      searchQuery: '',
      searchResults: [] // Array to store search results
    };
  },
  methods: {
    
  }
});
</script>



<style>

input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 300px; /* Adjust the width as needed */
}

</style> 
