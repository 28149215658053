<template>
    <div v-for="(category, key) in tableData" :key="key" class="mb-2 border-2 p-2 rounded-md">
        <div v-for="(requirement, key) in category" :key="key" class="mb-1 text-base font-medium">
            <div class="flex justify-between">
                <div class=" text-left ml-1">
                    <label class="font-bold">{{ key }}</label>
                </div>
                <div>
                {{ requirement.status + "/" + requirement.boundary }}
                </div>
            </div>
            <div v-if="key == 'Max Valfri hp'" class="relative w-full bg-gray-200 rounded-full h-2.5 mb-4">
                        <div class="bg-blue-600 h-2.5 rounded-full absolute" :style="{ width: calculateWidth(requirement.status, requirement.boundary) }"
                            :class="{
                            'bg-green-700': requirement.status >= requirement.boundary
                            }"></div>
                        <div v-if="category['Max Externt Valfria HP'].status <= category['Max Externt Valfria HP'].boundary" class="bg-green-500 h-2.5 rounded-full absolute" :style="{ width: calculateWidth(category['Max Externt Valfria HP'].status, requirement.boundary) }"></div>
                        <div v-else class="bg-yellow-500 h-2.5 rounded-full absolute" style="width: 33.33%"></div>
            </div>
            <div v-else-if="key == 'Max Externt Valfria HP'" class="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                <div class="bg-green-500 h-2.5 rounded-full" :style="{ width: calculateWidth(requirement.status, requirement.boundary) }"
                    :class="{
                    'bg-yellow-500': requirement.status > requirement.boundary
                    }">
                </div>
            </div>        
            <div v-else class="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                <div class="bg-blue-600 h-2.5 rounded-full" :style="{ width: calculateWidth(requirement.status, requirement.boundary) }" 
                    :class="{
                            'bg-green-500': requirement.status >= requirement.boundary 
                        }"></div>
                </div>
        </div>
    </div>

</template>


<script lang="ts">
import { ProgressHolder } from '@/services/typeHolder';
import { PropType, defineComponent } from 'vue';
export default defineComponent({
    name: "ProgressTable",
    props: {
    tableData: {
      type: Object as PropType<ProgressHolder>,
      required: true,
    },
  },
  methods: {
    calculateWidth(status: number, boundary: number): string {
        const percentage = (status / boundary) * 100;
        if(percentage >= 100){
            return `${100}%`;
        }
        return `${percentage}%`;
    }
    }

});

</script>