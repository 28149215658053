<template>
  <table class="w-full table-auto rounded-lg overflow-hidden">
    <thead class="bg-blue-500 text-white">
        
        <tr>
          <th class="w-12 py-2"></th> <!-- Aligns with the Y-axis header column -->
          <th class="py-2 px-4">
            <div class="flex">
            <div class="flex-1 px-4">LP1</div>
            <div class="flex-1 px-4">LP2</div>
            <div class="flex-1 px-4">LP3</div>
            <div class="flex-1 px-4">LP4</div>
          </div>
          </th>
        </tr>
      </thead>
    <tbody>
      <tr
        class="border px-4 py-2"
        v-for="(selectedCourses, courseYear) in gridData"
        :key="courseYear"
      >
      <td class="bg-blue-500 text-white py-2 text-center">{{ courseYear }}</td>
      
        <gridRow container :gutter="12" class="px-4 py-2">
          <gridRow v-for="sublist in selectedCourses" :key="sublist">
            <gridCol
              v-for="course in sublist"
              :key="course"
              :lg="course.timePlans.duration * 3"
              :mdOffset="course.timePlans.offset * 3"
            >
              <CourseWidget :course="course"></CourseWidget>
            </gridCol>
          </gridRow>
        </gridRow>
      
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import CourseWidget from "./CourseWidget.vue";
import { DisplaySelected} from "@/services/typeHolder";
import { defineComponent, PropType  } from 'vue';

export default defineComponent({
  name: "ScheduleGrid",
  components: {
    CourseWidget,
  },

  props: {
    gridData: {
      type: Object as PropType<DisplaySelected>,
      required: true,
    },
  },
});
</script>
