<template>
  <div class="scrollable-widget">
    <div v-if="isEmptyTableData" class="text-center">
      <label class="block text-lg">Select program to display courses</label>
    </div>
    <div
      class="mb-8"
      v-else
      v-for="(courseList, category) in tableData"
      :key="category"
    >
      <label class="block mb-1 ml-2 text-left text-xl font-bold underline">{{
        category
      }}</label>
      <table class="w-full rounded-lg overflow-hidden">
        <thead>
          <tr class="bg-gray-400">
            <th class="px-4 py-2" style="text-align: left"></th>
            <th class="px-4 py-2 text-left" style="text-align: left">
              Kruskod
            </th>
            <th class="px-4 py-2 text-left" style="text-align: left">
              Kursnamn
            </th>
            <th class="px-4 py-2 text-left" style="text-align: left">Hp</th>
            <th class="px-4 py-2 text-left" style="text-align: left">Nivå</th>
            <th
              v-if="category != 'Exjobb'"
              class="px-4 py-2 text-left"
              style="text-align: left"
            >
              Läsperiod
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(course, index) in courseList"
            :key="index"
            :class="{
              'bg-white': index % 2 === 0,
              'bg-gray-200': index % 2 !== 0,
            }"
          >
            <td>
              <div class="flex items-center justify-center h-full">
                <input
                  type="checkbox"
                  :checked="course.selected"
                  @change="atChecked(course, $event)"
                  class="accent-blue-500 h-5 w-5"
                />
              </div>
            </td>
            <td style="text-align: left">{{ course.courseCode }}</td>
            <td style="text-align: left">{{ course.name_sv }}</td>
            <td style="text-align: left">{{ course.credits }}</td>
            <td style="text-align: left">{{ course.cycle }}</td>
            <td>
              <tbody v-if="course.groupId != 'exjobb'">
                <gridRow container :gutter="6" class="px-4 py-2">
                  <gridCol
                    v-for="(lp, pos) in course.timePlans.lps"
                    :key="pos"
                    :lg="3"
                  >
                    <LPWidget :lps="lp" :index="pos"></LPWidget>
                  </gridCol>
                </gridRow>
              </tbody>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="showPopup"
      class="popup flex flex-col items-center justify-center p-4"
      :style="{ top: popupTop + 'px', left: popupLeft + 'px' }"
      ref="popupBox"
    >
      <div class="text-center mb-1">
        <p>Choose year to add {{ selectedCourse?.courseCode }} :</p>
      </div>
      <div class="flex justify-center space-x-4">
        <button class="btn-submit" @click="handlePopupAction(true, 'År 4')">
          År 4
        </button>
        <button class="btn-submit" @click="handlePopupAction(true, 'År 5')">
          År 5
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions } from "vuex";
import { defineComponent } from "vue";
import { CourseObject } from "@/services/typeHolder";
// import { LPWidget } from "./LPWidget.vue";
import LPWidget from "./LPWidget.vue";

export default defineComponent({
  name: "CourseList",
  data() {
    return {
      showPopup: false,
      selectedCourse: null as CourseObject | null,
      intendedState: false,
      popupTop: 0,
      popupLeft: 0,
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  components: {
    LPWidget,
  },
  props: {
    tableData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // Computed property to check if tableData is empty
    isEmptyTableData(): boolean {
      return Object.keys(this.tableData).length === 0;
    },
  },
  methods: {
    ...mapActions(["addCourse", "removeCourse"]),

    atChecked(course: CourseObject, event: Event) {
      this.selectedCourse = course;
      course.selected = !course.selected;
      if (!course.selected) {
        this.removeCourse({ course });
      } else {
        const target = event.target as HTMLInputElement;
        const checkboxRect = target.getBoundingClientRect();
        this.popupTop = checkboxRect.bottom;
        this.popupLeft = checkboxRect.left;
        this.showPopup = true;
      }
    },
    handlePopupAction(shouldAdd: boolean, year: string) {
      if (!this.selectedCourse) return;

      if (shouldAdd) {
        this.addCourse({ course: this.selectedCourse, year });
      } else {
        this.removeCourse(this.selectedCourse);
      }
      this.showPopup = false; // Close the popup
    },
    handleClickOutside(event: MouseEvent) {
      const target = event.target as Node;
      const popupBox = this.$refs.popupBox as HTMLElement | undefined;
      if (
        this.showPopup &&
        popupBox &&
        !popupBox.contains(target) &&
        this.selectedCourse
      ) {
        this.showPopup = false;
        this.selectedCourse.selected = false;
      }
    },
  },
});
</script>

<style scoped>
.scrollable-widget {
  max-height: 420px;
  overflow-y: auto; /* Enables vertical scrolling when needed */
  border: 1px solid #ccc;
  padding: 2px;
}

.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
}

.btn-submit {
  @apply bg-blue-500 text-white font-bold px-2 py-1  rounded-lg transition duration-100 ease-in-out;
  @apply hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50;
}
</style>
